import React from 'react'

import Onboarding from './Onboarding'

import '../stylesheets/components/Page.scss'

export default class Page extends React.Component {
    state = {
        onboarding: false,
    }
    goBack = () => {
        this.props.history.goBack()
    }
    closeOnboarding = () => {
        this.setState({ onboarding: false }, () => {
            window.localStorage.setItem('onboarding', true)
        })
    }
    componentDidMount = () => {
        if (!window.localStorage.getItem('onboarding') && this.props.onboarding)
            this.setState({ onboarding: true })
    }
    render() {
        let title =
            typeof this.props.structure === 'string'
                ? this.props.structure
                : this.props.structure.find(item => item.id === this.props.match.params.id)
                ? this.props.structure.find(item => item.id === this.props.match.params.id).title
                : null
        return (
            <>
                <div className="Page">
                    <div className="Page_header">
                        <h2>{title ? title : 'Anapptomy'}</h2>
                        {title && <div className="Page_back" onClick={() => this.goBack()} />}
                    </div>
                    <div className="Page_content">
                        <div className="Page_transition">
                            {React.cloneElement(this.props.children, this.props)}
                        </div>
                    </div>
                </div>
                <Onboarding close={this.closeOnboarding} visible={this.state.onboarding} />
            </>
        )
    }
}

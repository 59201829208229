import React from 'react'

import '../stylesheets/components/About.scss'

export default class About extends React.Component {
    render() {
        return (
            <div className="About">
                <p>
                    Het idee voor Anapptomy is ontstaan tijdens het coschap Heelkunde van
                    geneeskunde studenten bij het EMC in Rotterdam. Op zoek naar een manier om de
                    voorbereiding op operaties gemakkelijker te maken ontwikkelden zij een
                    hedendaagse tool om chirurgische benaderingen te belichten. Anapptomy heeft zich
                    inmiddels een gewilde aanvulling op de regulier opleidingen, anatomische boeken,
                    en het internet bewezen.
                </p>
                <p>
                    Om volledig- en betrouwbaarheid te waarborgen wordt momenteel samengewerkt met
                    specialisten uit verschillende vakgebieden. Anapptomy zal zich blijvend inzetten
                    een toegankelijk en modern platform te bieden waar je jezelf kunt verdiepen in
                    alle veel voorkomende chirurgische benaderingen. Of je nu thuis, op je tablet,
                    op je laptop of tussen twee operaties door snel nog even wat wilt opzoeken. En
                    dit allemaal kosteloos en gemakkelijk toegankelijk!
                </p>
                <p>
                    Meer informatie:{' '}
                    <a href="http://www.anapptomy.com/" target="_blank" rel="noopener noreferrer">
                        www.anapptomy.com
                    </a>
                </p>
            </div>
        )
    }
}

import React from 'react'
import { CSSTransition } from 'react-transition-group'

import '../stylesheets/components/Onboarding.scss'

export default class Onboarding extends React.Component {
    state = {
        step: 0,
    }
    steps = [
        'Swipe tussen de operatie stappen',
        'Toon structuren',
        'Tap op een highlight voor meer info',
        'Swipe omhoog voor verdiende informatie',
    ]
    handleNext = () => {
        if (this.state.step < this.steps.length - 1) {
            this.setState(state => ({ step: state.step + 1 }))
        } else {
            this.props.close()
        }
    }
    render() {
        return (
            <CSSTransition
                in={this.props.visible}
                timeout={500}
                classNames="fx"
                mountOnEnter
                unmountOnExit>
                <div className="Onboarding">
                    <div className="Onboarding_header">
                        {this.steps.map((step, key) => (
                            <CSSTransition
                                in={key === this.state.step}
                                key={key}
                                timeout={1000}
                                classNames="fx"
                                mountOnEnter
                                unmountOnExit>
                                <p>{step}</p>
                            </CSSTransition>
                        ))}
                    </div>
                    <div className="Onboarding_content">
                        <div className="Onboarding_canvas">
                            <div className="Onboarding_layer">
                                <img src="/images/onboarding/underlay.png" alt="" />
                            </div>
                            <div className="Onboarding_layer">
                                <img
                                    src={'/images/onboarding/' + this.state.step + '.gif'}
                                    alt=""
                                />
                            </div>
                            <div className="Onboarding_layer">
                                <img src="/images/onboarding/overlay.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="Onboarding_footer">
                        <button onClick={this.handleNext}>
                            {this.state.step < this.steps.length - 1 ? 'Volgende' : 'Starten'}
                        </button>
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

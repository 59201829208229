import React from 'react'
import { documentToHtmlString as html } from '@contentful/rich-text-html-renderer'

export default class Rich extends React.Component {
    parse = input => {
        return this.props.plain ? html(input) : html(input)
    }
    render() {
        return <div dangerouslySetInnerHTML={{ __html: this.parse(this.props.children) }} />
    }
}

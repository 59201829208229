import axios from 'axios'

export default {
    get: (url, data = {}) => {
        return axios({
            method: 'GET',
            url:
                process.env.REACT_APP_ENDPOINT +
                url +
                '?' +
                Object.entries(data)
                    .map(([key, val]) => `${key}=${val}`)
                    .join('&'),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {},
        })
    },
}

import React from 'react'

import '../stylesheets/components/Disclaimer.scss'

export default class Disclaimer extends React.Component {
    render() {
        return (
            <div className="Disclaimer">
                <h2>Algemeen</h2>
                <p>
                    Door gebruik te maken van de Anapptomy webpagina of de Anapptomy applicatie
                    stemt u in met deze disclaimer.
                </p>
                <p>
                    Alle informatie die door Anapptomy beschikbaar wordt gesteld is gericht op de
                    herhaling en bestudering van relevante anatomie voor veel voorkomende
                    chirurgische ingrepen. De informatie op deze website is geenszins een basis voor
                    het uitvoeren van operaties.
                </p>
                <p>
                    Anapptomy spant zich in om de informatie op haar website en in de applicatie zo
                    volledig en nauwkeurig mogelijk weer te geven. Anapptomy aanvaardt echter geen
                    enkele verantwoordelijkheid voor schade op welke manier dan ook ontstaan door
                    gebruik, onvolledigheid of onjuistheid van de aangeboden informatie.
                </p>
                <p>
                    De informatie op deze website en in de applicatie kan zonder voorafgaande
                    waarschuwing of kennisgeving worden gewijzigd.
                </p>
                <h2>Auteursrecht</h2>
                <p>
                    Alle rechten waaronder intellectuele eigendomsrechten op alle inhoudelijke
                    informatie en het beeldmateriaal op de website blijven te allen tijde
                    voorbehouden aan Anapptomy. Gebruik hiervan is enkel toegestaan met voorafgaande
                    schriftelijke toestemming van Anapptomy.
                </p>
            </div>
        )
    }
}

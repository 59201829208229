import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ReactGA from 'react-ga'

import Article from './Article'
import Disclaimer from './Disclaimer'
import Structure from './Structure'
import About from './About'
import Page from './Page'

import '../stylesheets/components/Animator.scss'

export default class Animator extends React.Component {
    state = {
        action: '',
    }
    registerPageView = path => {
        if (process.env.NODE_ENV === 'production') ReactGA.pageview(path)
    }
    componentWillReceiveProps = () => {
        this.setState({ action: this.props.history.action })
    }
    componentDidMount = () => {
        ReactGA.initialize('UA-146342345-1')
        this.props.history.listen(location => this.registerPageView(location.pathname))
        this.registerPageView(this.props.location.pathname)
    }
    render() {
        return (
            <div className={['Animator', this.state.action].join(' ')}>
                <TransitionGroup>
                    <CSSTransition key={this.props.location.key} timeout={500} classNames="fx">
                        <Switch location={this.props.location}>
                            <Route
                                path="/about"
                                render={props => (
                                    <Page {...props} onboarding={false} structure="Over Anapptomy">
                                        <About />
                                    </Page>
                                )}
                            />
                            <Route
                                path="/disclaimer"
                                render={props => (
                                    <Page {...props} onboarding={false} structure="Disclaimer">
                                        <Disclaimer />
                                    </Page>
                                )}
                            />
                            <Route
                                path="/article/:id"
                                render={props => (
                                    <Page
                                        {...props}
                                        onboarding={true}
                                        structure={this.props.structure}>
                                        <Article />
                                    </Page>
                                )}
                            />
                            <Route
                                path="/:id?"
                                render={props => (
                                    <Page
                                        {...props}
                                        onboarding={false}
                                        structure={this.props.structure}>
                                        <Structure />
                                    </Page>
                                )}
                            />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        )
    }
}

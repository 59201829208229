import React from 'react'
import * as Hammer from 'hammerjs'
import cx from 'classnames'

import Loader from './Loader'
import Drag from './Drag'
import Rich from './Rich'
import api from '../shared/api'

import '../stylesheets/components/Article.scss'

export default class Article extends React.Component {
    state = {
        lock: false,
        scaling: false,
        scale: 1,
        x: 0.5,
        y: 0.5,
        ox: 0,
        oy: 0,
        data: null,
        step: 0,
        overlay: false,
        highlight: null,
        open: false,
        dragging: false,
        extra: false,
    }
    handleKeyEvent = e => {
        if (e.keyCode === 32) this.toggleOverlay()
        if (e.keyCode === 37) this.stepLeft()
        if (e.keyCode === 39) this.stepRight()
    }
    stepLeft = () => {
        let current = this.state.step
        let next = current - 1
        if (next < 0) next = 0
        this.setState({ step: next })
        if (next !== current) this.setState({ extra: false, highlight: null, overlay: false })
    }
    stepRight = () => {
        let current = this.state.step
        let next = current + 1
        if (next > this.state.data.steps.length - 1) next = this.state.data.steps.length - 1
        this.setState({ step: next })
        if (next !== current) this.setState({ extra: false, highlight: null, overlay: false })
    }
    changeOpen = state => {
        this.setState({ open: state })
        if (!state) document.getElementsByClassName('Article_card')[0].scrollTo(0, 0)
    }
    toggleOverlay = () => {
        if (!this.state.lock) this.setState(state => ({ overlay: !state.overlay, highlight: null }))
    }
    toggleExtra = () => {
        this.setState(state => ({ extra: !state.extra }))
    }
    selectHighlight = (e, id) => {
        if (this.state.overlay) {
            e.stopPropagation()
            this.setState({ highlight: id, extra: false })
        }
    }
    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyEvent)
    }
    componentDidMount = async () => {
        const response = await api.article(this.props.match.params.id)
        this.setState({ data: response.data })
        let hammer = new Hammer(document.getElementsByClassName('Article_slider')[0])
        let startPos = 0
        let x
        let y
        hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 10 }))
        hammer.add(new Hammer.Pinch())
        hammer.on('pinchstart', e => {
            x = e.center.x
            y = e.center.y
            this.setState({
                scaling: true,
                x: (1 / window.innerWidth) * e.center.x,
                y: (1 / window.innerWidth) * e.center.y,
            })
        })
        hammer.on('pinchend', e => {
            this.setState({ lock: true, scale: 1, ox: 0, oy: 0, scaling: false }, () => {
                window.setTimeout(() => {
                    this.setState({ lock: false, x: 0.5, y: 0.5 })
                }, 250)
            })
        })
        hammer.on('pinch', e => {
            if (!this.state.lock) {
                let scale = e.scale
                let ox = -(x - e.center.x)
                let oy = -(y - e.center.y) + (e.scale - 1) * 50
                if (scale < 1) {
                    scale = 1
                    ox = 0
                    oy = 0
                }
                this.setState({ scale, ox, oy })
            }
        })
        var calculateXOffset = () => {
            var raw = window.getComputedStyle(
                document.getElementsByClassName('Article_slider_container')[0],
            ).transform
            if (raw === 'none') return 0
            var parts = raw.split(', ')
            var last = parts[parts.length - 2]
            return parseInt(last)
        }
        hammer.on('pan', e => {
            if (!this.state.dragging) {
                this.setState({ dragging: true })
                startPos = calculateXOffset()
            }
            var delta = e.deltaX
            var pos = startPos + delta
            document.getElementsByClassName('Article_slider_container')[0].style.transform =
                'translateX(' + pos + 'px)'
            if (e.isFinal) {
                this.setState({ dragging: false }, () => {
                    if (this.state.step > 0 && delta >= document.documentElement.clientWidth / 4) {
                        this.stepLeft()
                    } else if (
                        this.state.step < this.state.data.steps.length - 1 &&
                        delta <= -(document.documentElement.clientWidth / 4)
                    ) {
                        this.stepRight()
                    } else {
                        document.getElementsByClassName(
                            'Article_slider_container',
                        )[0].style.transform = 'translateX(' + startPos + 'px)'
                    }
                })
            }
        })
        document.addEventListener('keydown', this.handleKeyEvent)
    }
    render() {
        let step = this.state.data ? this.state.data.steps[this.state.step] : null
        let highlight =
            step && this.state.highlight
                ? this.state.data.steps[this.state.step].highlights.find(
                      highlight => highlight.id === this.state.highlight,
                  )
                : null
        return this.state.data ? (
            <div className="Article">
                <div className="Article_carousel">
                    <div
                        className={cx('Article_slider', {
                            Article_animate: !this.state.dragging,
                            Article_scaling: this.state.scaling,
                        })}>
                        <div
                            className="Article_slider_container"
                            style={{ transform: 'translateX(-' + this.state.step * 100 + '%)' }}>
                            {this.state.data.steps.map(step => (
                                <div
                                    className="Article_slide"
                                    key={step.id}
                                    onClick={() => this.toggleOverlay()}>
                                    <div
                                        className={cx('Article_zoom', {
                                            Article_transition: this.state.lock,
                                        })}
                                        style={{
                                            transformOrigin: `${this.state.x * 100}% ${this.state
                                                .y * 100}%`,
                                            transform: `translate3d(${this.state.ox}px, ${this.state.oy}px, 0) scale3d(${this.state.scale}, ${this.state.scale}, 100)`,
                                        }}>
                                        <div className="Article_image">
                                            <img src={step.image} alt="" />
                                        </div>
                                        {step.overlay && !!step.highlights.length && (
                                            <div
                                                className={cx('Article_overlay', {
                                                    Article_overlay_active: this.state.overlay,
                                                })}>
                                                <img src={step.overlay} alt="" />
                                                {step.highlights.map(highlight => (
                                                    <div
                                                        className={cx('Article_point', {
                                                            Article_point_active:
                                                                this.state.highlight ===
                                                                highlight.id,
                                                        })}
                                                        style={{
                                                            top: `calc(${highlight.y}% - 15px)`,
                                                            left: `calc(${highlight.x}% - 15px)`,
                                                            transform: `scale3d(${1 /
                                                                this.state.scale}, ${1 /
                                                                this.state.scale}, 1)`,
                                                        }}
                                                        key={highlight.id}
                                                        onClick={e =>
                                                            this.selectHighlight(e, highlight.id)
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className={cx('Article_hint', {
                                Article_hint_active: this.state.overlay,
                            })}>
                            <i />
                            <p>Toon structuren</p>
                        </div>
                    </div>
                    <div className="Article_indicators">
                        <ul>
                            {this.state.data.steps.map((step, i) => (
                                <li
                                    key={i}
                                    className={cx({
                                        Article_indicator_active: i === this.state.step,
                                    })}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="Article_carousel_go_left" onClick={this.stepLeft} />
                    <div className="Article_carousel_go_right" onClick={this.stepRight} />
                </div>
                <div className="Article_information">
                    {highlight ? (
                        <div className="Article_information_holder">
                            <h3>
                                <em />
                                {highlight.title}
                            </h3>
                            <Rich>{highlight.description}</Rich>
                        </div>
                    ) : (
                        <div className="Article_information_holder">
                            <h3>
                                Stap {this.state.step + 1}: {step.title}
                            </h3>
                            <Rich>{step.description}</Rich>
                        </div>
                    )}
                </div>
                <div className="Article_desktop">
                    {highlight ? (
                        <div className="Article_desktop_holder">
                            <h3>
                                <em />
                                {highlight.title}
                            </h3>
                            <Rich>{highlight.description}</Rich>
                            {!!highlight.extra && (
                                <button
                                    onClick={this.toggleExtra}
                                    className={cx({ Acticle_button_active: this.state.extra })}>
                                    Verdiepende informatie
                                </button>
                            )}
                            {!!highlight.extra && this.state.extra && (
                                <div className="Article_desktop_extra">
                                    <Rich>{highlight.extra}</Rich>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="Article_desktop_holder">
                            <h3>
                                Stap {this.state.step + 1}: {step.title}
                            </h3>
                            <Rich>{step.description}</Rich>
                            {!!step.extra && (
                                <button
                                    onClick={this.toggleExtra}
                                    className={cx({ Acticle_button_active: this.state.extra })}>
                                    Verdiepende informatie
                                </button>
                            )}
                            {!!step.extra && this.state.extra && (
                                <div className="Article_desktop_extra">
                                    <Rich>{step.extra}</Rich>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Drag
                    change={this.changeOpen}
                    active={(!!highlight && !!highlight.extra) || (!highlight && !!step.extra)}>
                    {highlight ? (
                        <div className="Article_card">
                            <div className="Article_card_holder">
                                <h3>Verdiepende informatie</h3>
                                <Rich>{highlight.extra}</Rich>
                            </div>
                        </div>
                    ) : (
                        <div className="Article_card">
                            <div className="Article_card_holder">
                                <h3>Verdiepende informatie</h3>
                                <Rich>{step.extra}</Rich>
                            </div>
                        </div>
                    )}
                </Drag>
            </div>
        ) : (
            <Loader />
        )
    }
}

import React from 'react'
import cx from 'classnames'

import '../stylesheets/components/Structure.scss'

export default class Structure extends React.Component {
    componentDidMount = () => {
        this.ref = React.createRef()
    }
    componentDidUpdate = () => {
        if (this.ref.current)
            this.ref.current.scrollTo(
                0,
                window.sessionStorage.getItem(this.props.match.params.id) || 0,
            )
    }
    navigateCategory = (e, id) => {
        e.stopPropagation()
        this.props.history.push('/' + id)
        window.sessionStorage.setItem(this.props.match.params.id, this.ref.current.scrollTop)
    }
    navigateArticle = (e, id) => {
        e.stopPropagation()
        this.props.history.push('/article/' + id)
        window.sessionStorage.setItem(this.props.match.params.id, this.ref.current.scrollTop)
    }
    navigateAbout = () => {
        this.props.history.push('/about')
    }
    navigateDisclaimer = () => {
        this.props.history.push('/disclaimer')
    }
    render() {
        let match = this.props.match.params.id || null
        return (
            <div
                className={cx('Structure', {
                    Structure_start: !match,
                })}>
                {!match && (
                    <div className="Structure_credits">
                        <div className="Structure_links">
                            <ul>
                                <li onClick={this.navigateAbout}>Over Anapptomy</li>
                                <li onClick={this.navigateDisclaimer}>Disclaimer</li>
                            </ul>
                        </div>
                    </div>
                )}
                {this.props.structure.filter(item => item.parent === match).length ? (
                    <div className="Structure_list" ref={this.ref}>
                        {this.props.structure
                            .filter(item => item.parent === match)
                            .map(item =>
                                item.type === 'category' ? (
                                    <div
                                        key={item.id}
                                        className="Structure_item Structure_category"
                                        onClick={e => this.navigateCategory(e, item.id)}>
                                        <h3>{item.title}</h3>
                                        {item.description && <p>{item.description}</p>}
                                    </div>
                                ) : (
                                    <div
                                        key={item.id}
                                        className="Structure_item Structure_article"
                                        onClick={e => this.navigateArticle(e, item.id)}>
                                        <h3>{item.title}</h3>
                                        {item.description && <p>{item.description}</p>}
                                    </div>
                                ),
                            )}
                    </div>
                ) : (
                    <div className="Structure_empty">Geen inhoud gevonden</div>
                )}
            </div>
        )
    }
}

import call from './call.js'

export default {
    structure: () => {
        return call.get('/structure', {})
    },
    article: id => {
        return call.get('/article/' + id, {})
    },
}

import React from 'react'
import * as Hammer from 'hammerjs'
import cx from 'classnames'

import '../stylesheets/components/Drag.scss'

export default class Article extends React.Component {
    state = {
        dragging: false,
        open: false,
    }
    toggleCard = () => {
        if (this.props.active)
            this.setState(
                state => ({ open: !state.open }),
                () => (this.backdropRef.style.opacity = this.state.open ? 1 : 0),
            )
    }
    componentDidUpdate = (props, state) => {
        if (state.open !== this.state.open) this.props.change(this.state.open)
    }
    componentDidMount = () => {
        this.dragRef = document.getElementsByClassName('Drag_dragger')[0]
        this.cardRef = document.getElementsByClassName('Drag_card')[0]
        this.backdropRef = document.getElementsByClassName('Drag_backdrop')[0]
        let hammer = new Hammer(this.dragRef)
        let startPos = 0
        hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }))
        hammer.add(new Hammer.Tap())
        hammer.on('tap', e => {
            this.toggleCard()
        })
        var calculateYOffset = () => {
            var raw = window.getComputedStyle(this.cardRef).transform
            if (raw === 'none') return 0
            var parts = raw.split(', ')
            var last = parts[parts.length - 1]
            return parseInt(last.substring(0, last.length - 1))
        }
        hammer.on('pan', e => {
            if (!this.state.dragging) {
                this.setState({ dragging: true })
                startPos = calculateYOffset()
            }
            var delta = e.deltaY
            if (!this.state.open && delta > 0) delta = 0
            var pos = startPos + delta
            let diff =
                (Math.abs(delta) * 1) /
                (document.getElementsByClassName('Drag')[0].clientHeight * 0.5)
            diff = this.state.open ? 1 - diff : diff
            if (diff < 0) diff = 0
            if (diff > 1) diff = 1
            this.cardRef.style.transform = 'translateY(' + pos + 'px)'
            this.backdropRef.style.opacity = diff
            if (e.isFinal) {
                this.setState({ dragging: false }, () => {
                    let dest
                    if (delta < -100 && !this.state.open) {
                        dest = true
                    } else if (delta > 100 && this.state.open) {
                        dest = false
                    } else {
                        dest = this.state.open
                    }
                    this.setState({ open: dest }, () => {
                        this.cardRef.style.transform = ''
                        this.backdropRef.style.opacity = this.state.open ? 1 : 0
                    })
                })
            }
        })
    }
    render() {
        return (
            <div
                className={cx('Drag', {
                    Drag_animate: !this.state.dragging,
                    Drag_open: this.state.open,
                    Drag_active: this.props.active,
                })}>
                <div className="Drag_backdrop" onClick={() => this.toggleCard()} />
                <div className="Drag_card">
                    <div className="Drag_card_content">{this.props.children}</div>
                    <div className="Drag_dragger">
                        <div className="Drag_dragger_fade" />
                        <div className="Drag_dragger_handle" />
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Animator from './Animator'
import Loader from './Loader'
import api from '../shared/api'

import '../stylesheets/components/App.scss'

export default class App extends React.Component {
    state = {
        structure: null,
        alert: false,
        ios: false,
        android: false,
    }
    blockEvents = e => {
        e.stopPropagation()
    }
    componentDidMount = async () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        const isIos = () => {
            return /iphone|ipad|ipod/.test(userAgent)
        }
        const isAndroid = () => {
            return userAgent.indexOf('android') > -1
        }
        const isInStandaloneMode = () =>
            ('standalone' in window.navigator && window.navigator.standalone) ||
            window.matchMedia('(display-mode: fullscreen)').matches
        this.setState({ ios: isIos(), android: isAndroid(), alert: !isInStandaloneMode() })
        const response = await api.structure()
        this.setState({ structure: response.data })
    }
    render() {
        return (
            <div className="App">
                {this.state.structure ? (
                    <>
                        <div className="App_header" />
                        <Router>
                            <Route
                                render={({ location, history, match }) => (
                                    <Animator
                                        structure={this.state.structure}
                                        location={location}
                                        history={history}
                                        match={match}
                                    />
                                )}
                            />
                        </Router>
                    </>
                ) : (
                    <Loader />
                )}
                {this.state.alert &&
                    (this.state.ios || this.state.android) &&
                    process.env.NODE_ENV === 'production' && (
                        <div className="App_alert" onClick={this.blockEvents}>
                            <div className="App_alert_window">
                                <h3>Bijna klaar voor gebruik</h3>
                                {this.state.ios && (
                                    <p>
                                        Ga naar je browser instellingen en voeg Anapptomy toe aan je
                                        startscherm (<i className="ios" />
                                        ).
                                    </p>
                                )}
                                {this.state.android && (
                                    <p>
                                        Ga naar je browser instellingen en voeg Anapptomy toe aan je
                                        apps (<i className="android" />
                                        ). Ga dan naar al je apps toe en open Anapptomy.
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        )
    }
}
